<template>
  <div class="">
      <ContactIndex />
  </div>
</template>

<script>
// import ContactIndex from "@components/Contact/ContactIndex.vue"
import ContactIndex from "../components/Contact/ContactIndex.vue"
import util from "@/util/util";
// @ is an alias to /src
// import contactList from "@/components/Contact/ContactList";
export default {
  name: "Contacts",
  components: {
    // contactList,
    ContactIndex
  },

  methods: {
    headingTransformer: function (val) {
      return util.camelCase(val);
    },
    additionalColumnsTransformer() {
      return {
        full_name: (row) => {
          return [
            {
              item:
                row.first_name + " " + row.middle_name + " " + row.last_name,
              handler: () => null,
            },
          ];
        },
      };
    },
    rowClicked(row) {
      this.$router
        .push({ name: "single-contact", params: { id: row.id } })
        .catch(() => {});
    },
  },
};
</script>
<style></style>
